import React from 'react'
import {
  string, arrayOf, shape, bool,
} from 'prop-types'

import StyledLink from '../../../components/StyledLink'
import {
  MenuLink, MenuSubTitle, SubItem, MenutTitle,
} from './styles'

export const SimpleItem = ({
  title, subTitle, to, isCurrent,
}) => (
  <StyledLink to={to}>
    <MenuLink>
      <MenutTitle $isCurrent={isCurrent}>{title}</MenutTitle>
      {subTitle && (
        <MenuSubTitle>{subTitle}</MenuSubTitle>
      )}
    </MenuLink>
  </StyledLink>
)

SimpleItem.defaultProps = {
  subTitle: null,
  isCurrent: null,
}

SimpleItem.propTypes = {
  title: string.isRequired,
  subTitle: string,
  to: string.isRequired,
  isCurrent: bool,
}

export const ItemWithSub = ({
  title, subTitle, subItems, pathname,
}) => (
  <MenuLink>
    {title}
    {subTitle && (
    <MenuSubTitle>{subTitle}</MenuSubTitle>
    )}
    {
      subItems.map(({ title, to }) => (
        <StyledLink key={to} to={to}>
          <SubItem>
            <MenutTitle $isCurrent={pathname === to}>{title}</MenutTitle>
          </SubItem>
        </StyledLink>
      ))
    }
  </MenuLink>
)

ItemWithSub.defaultProps = {
  subTitle: null,
}

ItemWithSub.propTypes = {
  title: string.isRequired,
  subTitle: string,
  pathname: string.isRequired,
  subItems: arrayOf(shape({
    title: string.isRequired,
    to: string.isRequired,
  })).isRequired,
}
