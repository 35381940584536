/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React from 'react'
import { string, node } from 'prop-types'
import styled from 'styled-components'

import {
  AiOutlineUp,
  AiOutlineShoppingCart,
  AiFillDelete,
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineSearch,
} from 'react-icons/ai'
import { FaFacebook, FaInstagram } from 'react-icons/fa'

export const Facebook = ({ className, ...props }) => (
  <FaFacebook className={`old-tree-icons ${className}`} {...props} />
)

Facebook.defaultProps = {
  className: '',
}

Facebook.propTypes = {
  className: string,
}

export const Instagram = ({ className, ...props }) => (
  <FaInstagram className={`old-tree-icons ${className}`} {...props} />
)

Instagram.defaultProps = {
  className: '',
}

Instagram.propTypes = {
  className: string,
}

export const ArrowUp = ({ className, ...props }) => (
  <AiOutlineUp className={`old-tree-icons ${className}`} {...props} />
)

ArrowUp.defaultProps = {
  className: '',
}

ArrowUp.propTypes = {
  className: string,
}

export const DeleteIcon = ({ className, ...props }) => (
  <AiFillDelete className={`old-tree-icons ${className}`} {...props} />
)

DeleteIcon.defaultProps = {
  className: '',
}

DeleteIcon.propTypes = {
  className: string,
}

export const Close = ({ className, ...props }) => (
  <AiOutlineClose className={`old-tree-icons ${className}`} {...props} />
)

Close.defaultProps = {
  className: '',
}

Close.propTypes = {
  className: string,
}

export const Search = ({ className, ...props }) => (
  <AiOutlineSearch className={`old-tree-icons ${className}`} {...props} />
)

Search.defaultProps = {
  className: '',
}

Search.propTypes = {
  className: string,
}

export const Cart = ({ className, ...props }) => (
  <AiOutlineShoppingCart className={`old-tree-icons ${className}`} {...props} />
)

Cart.defaultProps = {
  className: '',
}

Cart.propTypes = {
  className: string,
}

export const BurgerMenu = ({ className, ...props }) => (
  <AiOutlineMenu className={`old-tree-icons ${className}`} {...props} />
)

BurgerMenu.defaultProps = {
  className: '',
}

BurgerMenu.propTypes = {
  className: string,
}
