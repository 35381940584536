import React, { useState, useEffect } from 'react'
import { string, arrayOf, shape } from 'prop-types'

import {
  DrawerItemContainer, DrawerTitle, SubMenuContainer, SubMenuItem, Title,
} from './styles'

const DrawerItem = ({
  id, title, subItems, pathname, route,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const windowIsAvailable = typeof window !== 'undefined' && window.document

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (windowIsAvailable) {
      const handleOuterClick = e => {
        const container = document.getElementById(`${id}-container`)
        const subMenu = document.getElementById(`${id}-sub-menu`)
        const clickInContainer = container?.contains(e.target)
        const clickInSubMenu = subMenu?.contains(e.target)

        if (clickInContainer || clickInSubMenu) return
        setIsOpen(false)
      }

      document.addEventListener('click', handleOuterClick)

      return () => {
        document.removeEventListener('click', handleOuterClick)
      }
    }
  }, [id, windowIsAvailable])

  const onClick = () => {
    setIsOpen(b => !b)
  }

  return (
    <DrawerItemContainer
      id={`${id}-container`}
      onClick={onClick}
    >
      <DrawerTitle $isCurrent={pathname.startsWith(route)}>{title}</DrawerTitle>
      {isOpen && (
        <SubMenuContainer id={`${id}-sub-menu`}>
          {
            subItems.map(item => (
              <SubMenuItem key={item.to}>
                <Title to={item.to}>
                  {item.title}
                </Title>
              </SubMenuItem>
            ))
          }
        </SubMenuContainer>
      )}
    </DrawerItemContainer>
  )
}

DrawerItem.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  pathname: string.isRequired,
  route: string.isRequired,
  subItems: arrayOf(shape({
    title: string.isRequired,
    to: string.isRequired,
  }).isRequired).isRequired,
}

export default DrawerItem
