import styled, { css } from 'styled-components'

export const Body = styled.div`
  height: calc(100vh - ${({ theme }) => theme.layout.header_height});
  overflow: auto;
`

const addPadding = ({ withPadding }) => withPadding ?
  css`
    padding: 15px;
  `
  : css``

export const Main = styled.div`
  margin: 0px auto 60px;
  ${addPadding}
  min-height: calc(100vh - ${({ theme }) => theme.layout.header_height});
  max-width: ${({
    restricted, theme: { layout },
  }) => restricted ? layout.main_max_width : 'none'};
  display: block;
  background-color: ${({ theme }) => theme.color.primary.dark};
`
