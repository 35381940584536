import styled, { css } from 'styled-components'
import { BurgerMenu, Close } from '../../../components/Icons'

export const MobileMenuContainer = styled.div`
  width: 250px;
  height: 100vh;
  z-index: 99;
  position: absolute;
  top: 0;
  left: ${({ isOpen }) => isOpen ? 0 : '-250px'};
  transition: all ${({ theme: { animation } }) =>
    `${animation.time_animation} ${animation.func_animation}`};
  background-color: ${({ theme: { color } }) => color.primary.main};
  border-right: 1px solid ${({ theme: { color } }) => color.primary.light};
`

export const IconContainer = styled.div`
  display: flex;
  padding: 15px;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
`

export const MenuLinkContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  background-color: ${({ theme: { color } }) => color.primary.main};
`

const isCurrentLink = ({ $isCurrent }) => $isCurrent
  ? css`
    border-bottom: 3px solid ${({ theme: { color } }) => color.secondary.main};
    color: ${({ theme: { color } }) => color.secondary.main} !important;
    padding-bottom: 3px;
  `
  : css``

export const MenuLink = styled.div`
  color: ${({ theme: { color } }) => color.text};
  min-height: 50px;
  padding: 10px 10px 10px 20px;
  margin-bottom: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
  
`

export const MenutTitle = styled.span`
  ${isCurrentLink}
`

export const MenuSubTitle = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.xs};
`

export const MenuIcon = styled(BurgerMenu)`
  @media(min-width: ${({ theme }) => theme.breakPoint.tablet}) {
      visibility: hidden;
  }
`

export const SubItem = styled.div`
  padding: 10px 10px 10px 25px;
  min-height: 45px;
`

export const CloseIcon = styled(Close)`
  font-size: 26px !important;
`
