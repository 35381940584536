import styled, { css } from 'styled-components'
import StyledLink from '../../../components/StyledLink'

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  bottom: 2px;
`
const hideNavItem = ({ isCart }) => isCart ?
  css`display: block;`
  : css`display: none;`

const reAlign = ({ isCart }) => isCart ?
  css`` :
  css`
    position: relative;
    bottom: 3px;
  `

export const ItemContainer = styled.div`
  margin-left: 25px;
  padding: 0;
  ${hideNavItem}
  ${reAlign}

  @media(min-width: ${({ theme }) => theme.breakPoint.tablet}) {
    display: flex;
    align-items: center;
  }
`

const isCurrentLink = ({ $isCurrent }) => $isCurrent
  ? css`
    border-bottom: 3px solid ${({ theme: { color } }) => color.secondary.main};
    color: ${({ theme: { color } }) => color.secondary.main} !important;
  `
  : css``

export const Title = styled(StyledLink)`
  font-weight: normal;
  display: block;
  padding-bottom: 3px;
  ${isCurrentLink}

  &:hover {
    color: ${({ theme: { color } }) => color.secondary.main};
  }
`

export const DrawerItemContainer = styled(ItemContainer)`
  position: relative;
  cursor: pointer;
`

export const DrawerTitle = styled.span`
  padding-bottom: 3px;
  ${isCurrentLink}

  &:hover {
    color: ${({ theme: { color } }) => color.secondary.main};
  }
`

export const SubMenuContainer = styled.div`
  min-width: 80px;
  padding: 10px;
  border-radius: 4px;
  position: absolute;
  top: 25px;
  left: -5px;
  z-index: 10;
  background-color: ${({ theme: { color } }) => color.primary.main};
`

export const SubMenuItem = styled.div`
  min-height: 30px;
`
