import styled, { css } from 'styled-components'
import { Cart } from '../../../../components/Icons'
import Link from '../../../../components/StyledLink'

const isCurrent = ({ $isCurrent }) => $isCurrent
  ? css`
    color: ${({ theme: { color } }) => color.secondary.main} !important;
  `
  : css``

export const CartIcon = styled(Cart)`
  position: relative;
  bottom: 3px;
  font-size: 25px !important;
  ${isCurrent}

  &:hover {
    color: ${({ theme: { color } }) => color.secondary.main};
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  position: relative;

`

export const NotifBubble = styled.div`
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  width: 13px;
  height: 13px;
  border-radius: 7px;
  background-color: ${({ theme: { color } }) => color.red};
  border: 1px solid ${({ theme: { color } }) => color.red};
  position: absolute;
  z-index: 10;
  top: -8px;
  left: 18px;

  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CartPrice = styled.span`
  padding-left: 5px;
  padding-top: 5px;
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  color: ${({ isCurrent, theme: { color } }) => isCurrent
    ? color.secondary.main
    : color.text};
`
