import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
  color: ${({ theme, color }) => color || theme.color.text};
  cursor: pointer;
`

export const ClassicLink = styled.a`
  text-decoration: none;
  color: ${({ theme, color }) => color || theme.color.text};
  cursor: pointer;
`

export default StyledLink
