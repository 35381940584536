import React from 'react'
import styled from 'styled-components'

import StyledLink, { ClassicLink } from '../../components/StyledLink'
import { Facebook, Instagram } from '../../components/Icons'
import { FooterContainer, FooterContent, Item } from './styles'

export const FacebookIcon = styled(Facebook)`
  font-size: 26px !important;
  margin-bottom: 10px;
`

export const InstagramIcon = styled(Instagram)`
  font-size: 26px !important;
  margin-bottom: 10px;
`

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <Item left>
        <ClassicLink
          target="_blank"
          href="https://www.facebook.com/old.tree.collective/"
        >
          <FacebookIcon />
        </ClassicLink>
      </Item>
      <Item>
        <ClassicLink
          target="_blank"
          href="https://www.instagram.com/old.tree.collective/"
        >
          <InstagramIcon />
        </ClassicLink>
      </Item>
      <Item left>
        <StyledLink to="/contact">Contact</StyledLink>
      </Item>
      <Item>
        <StyledLink to="/about">À propos</StyledLink>
      </Item>
      <Item left>
        <StyledLink to="/faq">FAQ</StyledLink>
      </Item>
      <Item>
        <StyledLink to="/legals/confidentialite">
          Politique de confidentialité
        </StyledLink>
      </Item>
      <Item left>
        <StyledLink to="/legals/expedition">
          Politique d&apos;expédition
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="/legals/mentions">
          Mentions légales
        </StyledLink>
      </Item>
      <Item left>
        <StyledLink to="/legals/refund">
          Politique de remboursement
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="/legals/utilisation">
          Conditions d&apos;utilisation
        </StyledLink>
      </Item>
    </FooterContent>
  </FooterContainer>
)

export default Footer
