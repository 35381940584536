import styled from 'styled-components'

export const HeaderContainer = styled.div`
  height: ${({ theme }) => theme.layout.header_height};
  width: 100%;
  background-color: ${({ theme: { color } }) => color.primary.dark};
  padding: 15px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-gap: 10px;
  position: relative;

  @media(min-width: ${({ theme }) => theme.breakPoint.tablet}) {
    grid-template-columns: 2fr 1fr 2fr;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 6px;
`
export const BottomWhiteLine = styled.span`
  position: absolute;
  right: 0;
  bottom: 1px;
  width: 100%;
  height: 1px;
  background-color: ${({ theme: { color } }) => color.primary.light};

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    width: 415px;
  }

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.laptop}) {
    width: 518px;
  }
`
