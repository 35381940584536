import React, { useContext } from 'react'
import { useLocation } from '@reach/router'
import { ThemeContext } from 'styled-components'

import {
  CartIcon, StyledLink, NotifBubble, CartPrice,
} from './styles'

import CartContext from '../../../../Providers/CartProvider'

const Cart = () => {
  const { checkout } = useContext(CartContext)
  const { pathname } = useLocation()
  const { device } = useContext(ThemeContext)

  const itemsCount = checkout?.lineItems?.reduce(
    (acc, p) => acc + p.quantity, 0
  )

  const showPrice = device.isLaptop && itemsCount > 0 && checkout?.totalPrice
  const isCurrent = pathname === '/cart'

  return (
    <StyledLink to="/cart">
      <CartIcon $isCurrent={isCurrent} />
      <NotifBubble visible={itemsCount > 0}>
        {itemsCount > 0 && itemsCount < 10 ? itemsCount : null}
      </NotifBubble>
      {showPrice && (
        <CartPrice isCurrent={isCurrent}>
          {`${checkout?.totalPrice} €`}
        </CartPrice>
      )}
    </StyledLink>
  )
}

export default Cart
