import React from 'react'

import { HeaderContainer, LogoContainer, BottomWhiteLine } from './styles'
import MobileMenu from '../Menu/MobileMenu'
import LaptopMenu from '../Menu/LaptopMenu'

import Logo from '../../components/Logo'
import StyledLink from '../../components/StyledLink'

const Header = () => (
  <HeaderContainer>
    <MobileMenu />
    <LogoContainer>
      <StyledLink to="/">
        <Logo />
      </StyledLink>
    </LogoContainer>
    <LaptopMenu />
    <BottomWhiteLine />
  </HeaderContainer>
)

export default Header
