import styled from 'styled-components'
import StyledLink, { ClassicLink } from '../../components/StyledLink'

export const FooterContainer = styled.footer`
  background-color: black;
  color: ${({ theme }) => theme.color.text};
  min-height: 150px;
  padding-top: 15px;
  padding-bottom: 50px;
`

export const FooterContent = styled.div`
  max-width: ${({ theme: { layout } }) => layout.main_max_width};
  margin: 0px auto;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 35px;
  grid-row-gap: 10px;
`

export const Item = styled.div`
  display: flex;
  justify-content: ${({ left }) => left ? 'flex-end' : 'flex-start'};

  ${ClassicLink}, ${StyledLink} {
    text-align: ${({ left }) => left ? 'right' : 'left'};
  }
`
