import React from 'react'
import { node, bool } from 'prop-types'

import Header from './Header'
import Footer from './Footer'

import { Body, Main } from './styles'

const Layout = ({ children, withPadding, restricted }) => (
  <>
    <Header />
    <Body>
      <Main withPadding={withPadding} restricted={restricted}>
        {children}
      </Main>
      <Footer />
    </Body>
  </>
)

Layout.defaultProps = {
  withPadding: true,
  restricted: true,
}

Layout.propTypes = {
  children: node.isRequired,
  restricted: bool,
  withPadding: bool,
}

export default Layout
