import React, { useState } from 'react'

import MobileNav from './MobileNav'

import { MenuIcon } from './styles'

const MOBILE_MENU_ID = 'MOBILE_MENU_ID'

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const togleMenu = e => {
    e.stopPropagation()
    setIsOpen(op => !op)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <MenuIcon onClick={togleMenu} />
      <MobileNav id={MOBILE_MENU_ID} isOpen={isOpen} onClose={onClose} />
    </div>
  )
}

export default Menu
